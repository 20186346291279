@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'SK Concretica Trial';
  font-style: normal;
  font-weight: normal;
  src: local('SK Concretica Trial'), url('./Assets/sk-concretica-trial.woff') format('woff');
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gifdiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./Assets/static.gif');
  opacity: 0;
}

/* .navbarMain{
  background-image: url('./Assets/truffles.png');
} */

.cardBG:hover .gifdiv {
  opacity: 5%;
}

.service-mainHeading{
  font-family: 'Clash Display', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'DM Sans' 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parent:hover .icon{
  opacity: 0;
}

.parent:hover .image{
  opacity: 1;
  transition: all 500ms ease;
}

.sideText{
  font-family: 'SK Concretica Trial';
}

.dmSans{
  font-family: 'DM Sans', sans-serif;
}

.skConcretica{
  font-family: 'SK Concretica Trial';
}

.clashDisplay{
  font-family: 'Clash Display', sans-serif;
}

.openSans{
  font-family: 'Open Sans', sans-serif;
}

.miniCard{
  font-weight: 700;
}

.dottedBG{
  background: url(./Assets/dottedBG.png);
}


.logo-slide{
  animation: 35s slide linear infinite;
}

.logo-slide img{
  height: 120px;
  opacity: 70%;
}

@keyframes slide {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-268%);
  }
}

.logos:hover .logo-slide{
  animation-play-state: paused;
}

.logo-slide img:hover{
  scale: 1.2;
  opacity: 100%;
  transition: all 200ms;
}



.logo-slideRight{
  animation: 35s slideRight linear infinite;
}

.logo-slideRight img{
  height: 120px;
  opacity: 70%;
}

@keyframes slideRight {
  from{
    transform: translateX(-268%);
  }
  to{
    transform: translateX(0%);
  }
}

.logosRight:hover .logo-slideRight{
  animation-play-state: paused;
}

.logo-slideRight img:hover{
  scale: 1.2;
  opacity: 100%;
  transition: all 200ms;
}

.clients {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.overlay {
  position: relative;
  z-index: 1;
  width: 100%;
}

.image-div {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.image-div img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: none;
}
.purpleBG{
  width: auto;
  height: 200%;
  object-fit: cover;
}

.leftContent{
  position: sticky;
  top: 0;
  background: url(./Assets//VisibleWork\ Dotted.png);
  object-fit: contain;
}

.ojiBg{
  display: flex;
  width: 10rem;
  height: 10rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7.75rem;
  background: #3F0099;
}

.tag{
  border-radius: 0.7rem;
  border: 2px solid rgba(255, 255, 255, 0.10);
  padding: 6px 12px;
}

.foodAddaBg{
  display: flex;
  width: 10rem;
  height: 10rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7.75rem;
  background: #FEAE43;
}

.alphaBg{
  display: flex;
  width: 10rem;
  height: 10rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7.75rem;
  background: #0839B0;
}

.testimonials{
  width: calc(22rem * 12);
  animation: slide2 20s linear infinite;
}

@keyframes slide2 {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-55.7%);
  }
}

.testimonials:hover{
  animation-play-state: paused;
}

.testimonial{
  opacity: 70%;
}

.testimonial:hover{
  scale: 1.1;
  transition: 200ms;
  opacity: 100%;
}

.visbleMain {
  display: flex;
  align-items: flex-start;
}

.card {
  position: sticky;
  top: 0;
  background-color: #0C0C0C;
  border: 1px solid var(--Grey, #383838);
  border-radius: 1.25rem;
}

.connect{
  background: var(--Purple, #6343FF);
  /* overflow-x: hidden; */
}

.dotBG{
  background: url(./Assets/connect\ dotted\ left.png) repeat;
  height: 100%;
}

.footer-icon{
  border-radius: 17.1875rem;
  border: 0.7px solid #FFF;
  margin: 15px;
  padding: 30px;

}

.footer-icon2{
  border-radius: 1rem;
  border: 0.7px solid #FFF; 
  margin: 15px;
  padding: 30px;
}

.secondPart{
  background: url(./Assets//footer-artboard.png);
  position: relative;
}

.footer-bg{
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer-icons{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.service-main{
  width: 80%;
}

.serviceCardLottie{
  opacity: 0;
}

.serviceCardLottie:hover{
  opacity: 5%;
  transition: all 200ms ease;
}

.hero-cube{
  position: absolute;
  top: 4rem;
  right: -6rem;
  z-index: 10;
}

.burgerButton{
  display: none;
}

.snippetHeadMob{
  display: none;
}

.MobileNav{
  display: none;
}

.burgerButtonDiv{
  display: none;
}

.herostaticbg{
  background: url(./Assets/static.json);
}

.footerLinks:hover{
  scale: 1.1;
  transition: all 200ms;
  opacity: 1;
}

.workNav{
  display: flex;
  gap: 1rem;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.6rem;
}

.workNavItems{
  display: inline-flex;
  padding: 0.7rem 1rem 0.7rem 1rem;
  align-items: flex-start;
  border-radius: 0.875rem;
  border: 1px solid rgba(255, 255, 255, 0.10);
  text-transform: uppercase;
}

.workNavItems:hover{
  border: 1px solid #FFCC2E;
  cursor: pointer;
  scale: 1.1;
  transition: all 200ms;
}

.workGrid{
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  grid-gap: 2.5rem;
}

.deskCard{
  animation: deskCard 45s infinite linear;
}

@keyframes deskCard {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-100%);
  }
}

/* .slidesMain{
  overflow: hidden;
} */

.slideCards:hover .deskCard{
  animation-play-state: paused;
}

.slideCard:hover{
  transition: all ease 400ms;
  scale: 1.03;
  z-index: 100;
}

.inputField{
  all: unset;
  color: #fff;
  font-family: Clash Display;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.4375rem; /* 221.875% */
  border-bottom: dashed rgba(255, 255, 255, 0.20);
  width: 40%;
}


/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus{
  -webkit-text-fill-color: #fff;
} */

input:not(:-webkit-autofill) {
  background-color: #0C0C0C;
}

.inputField::placeholder{
  color: #383838;
  font-family: Clash Display;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.4375rem; /* 221.875% */
  opacity: 90%;
}

.check{
  color: #fff;
  opacity: 70%;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 0.8rem;
  border: 2px solid rgba(255, 255, 255,0.7);
  padding: 10px;
  cursor: pointer;
}

.checked{
  color: #fff;
  border: 2px solid #FFCC2E;
  opacity: 1;
  cursor: pointer;
}

.check input:checked{
  color: #FFF;
}

.workTags{
  font-size: 0.8rem;
  font-family: DM Sans;
  display: inline-flex;
  padding: 0.3rem 0.6rem;
  align-items: flex-start;
  border-radius: 0.75rem;
  border: 2px solid rgba(255, 255, 255, 0.10);
  display: none;
}

.workTagsBlack{
  color: #2D2D2D;
  font-family: DM Sans;
  font-size: 0.8rem;
  display: inline-flex;
  padding: 0.3rem 0.6rem;
  align-items: flex-start;
  border-radius: 0.75rem;
  border: 2px solid rgba(16, 16, 16, 0.10);
  display: none;
}

.projDiv:hover{
  scale: 1.05;
  transition: all 300ms ease;
}

.projDiv:hover .workTags{
  transition: all 500ms ease;
  display: flex;
}

.projDiv:hover .workTagsBlack{
  transition: all 500ms ease;
  display: flex;
}

.projectPageSubHeading{
  color: rgba(255, 255, 255, 0.40);
  font-family: 'SK Concretica Trial';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.projectPageSideTexts{
  color: #FFF;
  font-family: Clash Display;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}

.projectPageSideText{
  padding: 0.3rem 0.7rem;
  border-radius: 0.7rem;
  border: 1px solid #FFF;
  text-transform: uppercase;
}

/* .workImages{
  object-fit: contain;
} */

.carousal{
  animation: carousalAnimation infinite linear 25s;
}

.carousal:hover{
  animation-play-state: paused;
}

.carousal:hover img:hover{
  scale: 1.05;
  transition: all 200ms ease;
}

@keyframes carousalAnimation {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-300%);
  }
}

.envySlider{
  animation: envyAnimation 25s infinite linear;
  /* overflow: visible; */
}

.envySlider:hover{
  animation-play-state: paused;
}

.envySlider:hover img:hover{
  scale: 1.05;
  transition: all 200ms ease;
}

@keyframes envyAnimation {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-276%);
  }
}

.aplhaSlider{
  animation: alphaAnimation 25s infinite linear;
  /* overflow: visible; */
}

.aplhaSlider:hover{
  animation-play-state: paused;
}

.aplhaSlider:hover img:hover{
  scale: 1.05;
  transition: all 200ms ease;
}

@keyframes alphaAnimation {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-323%);
  }
}

.carousalHeader{
  background: url(../src/Assets//carousals/Artboard\ 1@2x\ 3.png);
}

.navColor{
  background-color: #0C0C0C;
}

.footBalanceSlider1{
  animation: footBalanceAnimation1 25s infinite linear;
}

.footBalanceSlider1:hover{
  animation-play-state: paused;
}

.footBalanceSlider1:hover img:hover{
  scale: 1.05;
  transition: all 200ms ease;
}

@keyframes footBalanceAnimation1 {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-180%);
  }
}

.footBalanceSlider2{
  animation: footBalanceAnimation2 25s infinite linear;
}

.footBalanceSlider2:hover{
  animation-play-state: paused;
}

.footBalanceSlider2:hover img:hover{
  scale: 1.05;
  transition: all 200ms ease;
}

@keyframes footBalanceAnimation2 {
  from{
    transform: translateX(-180%);
  }
  to{
    transform: translateX(0);
  }
}

.lynxSlider1{
  animation: lynxAnimation1 20s infinite linear;
}

.lynxSlider1:hover{
  animation-play-state: paused;
}

.lynxSlider1:hover img:hover{
  scale: 1.05;
  transition: all 200ms ease;
}

@keyframes lynxAnimation1 {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-150%);
  }
}

.lynxSlider2{
  animation: lynxAnimation2 15s infinite linear;
}

.lynxSlider2:hover{
  animation-play-state: paused;
}

.lynxSlider2:hover img:hover{
  scale: 1.05;
  transition: all 200ms ease;
}

@keyframes lynxAnimation2 {
  from{
    transform: translateX(-120%);
  }
  to{
    transform: translateX(0);
  }
}

/* .mainArrow{
  height: 50%;
  width: 50%;
} */

.serviceSubHeading{
  width: 16%;
}

@media (max-width:850px){
  .slideNav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transform: translateX(100vw);
    transition: all 1s ease-in;
    z-index: 500;
  }
  .slideNavActive{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: all 1s ease-in;
    transform: translateX(0);
    z-index: 500;
  }
  .crossButton{
    position: fixed;
    right: 10%;
  }
  .mobileView {
    overflow-x: hidden;
  }
  .service-main{
    width: 100%;
  }
  .hero-cube{
    position: absolute;
    top: 90%;
    left: 0%;
    width: 130%;
    flex-shrink: 0;
  }
  .mainHeading{
    color: #FFF;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 5.28125rem; /* 105.625% */
  }
  .mainHeadingSpan{
    color: #FFF;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 5.28125rem;
  }
  .headPara{
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.53125rem; /* 168.75% */
    width: 37.8125rem;
    z-index: 100;
  }
  .sideText{
    color: #FFF;
    text-align: right;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3rem; /* 352.5% */
    text-transform: uppercase;
    position: absolute;
    top: 30rem;
    right: 0;
  }
  .mainDotted{
    position: absolute;
    width: 65.8125rem;
    height: 27.5rem;
    top: 30rem;
  }
  .mainEllipse1{
    position: absolute;
    top: 30rem;
  }
  .mainEllipse2{
    display: none;
  }
  .mainArrow{
    position: absolute;
    display: flex;
    gap: 0px;
    top: 40%;
    right: 0%;
    z-index: 90;
  }
  .mainArrow p{
    position: absolute;
    top: 100%;
    width: 300%;
    color: #FFF;
    text-align: right;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.15rem; /* 92% */
  }
  .serviceHeading{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4rem;
    color: #FFF;
    font-family: Clash Display;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.53125rem; /* 126.563% */
    text-align: left;
    padding: 0rem 2rem;
  }
  .service-mainHeading{
    color: #FFF;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.53125rem; /* 126.563% */
  }
  .serviceSubHeading{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.65625rem; /* 165.625% */
    width: auto;
  }
  .serviceCards{
    display: flex;
    flex-wrap: wrap;
    gap: 3.5rem 1rem;
    margin: 0;
  }
  .serviceCard{
    width: 100%;
    height: auto;
    border: none;
    border-radius: 3%;
    border: 1px solid var(--Grey, #383838);
    margin: 0%;
  }
  .snippetHead{
    display: none;
  }
  .snippetHeadMob{
    display: block;
    color: #FFF;
    text-align: center;
    font-size: 3rem;
    line-height: 2.53125rem; /* 126.563% */
    margin-top: 0;
  }
  .slidesButton{
    margin: 0 auto;
    margin-top: 3rem;
    width: 13.875rem;
    height: 4.5rem;
    flex-shrink: 0;
    border-radius: 0.875rem;
    border: 1px solid #FFF;
    font-size: 1.25rem;
    /* background: url(<path-to-image>), lightgray -92382672126396480px -1024112734620590100px / 1664053872424383700% 1691450316963701000% no-repeat; */
  }
  .workMain{
    height: 47rem;
    width: 100%;
    margin: 0 auto;
    padding: 0%;
    padding-top: 5.2rem;
    z-index: 100;
  }
  .workAboutSection{
    width: 90%;
    height: 40rem;
    margin: 0 auto;
    padding: 0 ;
    padding-top: 3rem;
    border-radius: 1.25rem;
    border: 1px solid var(--Grey, #383838);
    background: var(--Black, #0D0C0D);
  }
  .workButton{
    display: none;
  }
  .workBG{
    display: none;
  }
  .workDiv{
    width: auto;
    margin: auto;
    padding: 0%;
    margin-bottom: 0%;
  }
  .workHeading{
    font-size: 3rem;
    line-height: 2.53125rem; /* 126.563% */
    margin: 0 1.63rem;
  }
  .workPara{
    width: auto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.65625rem; /* 165.625% */
    margin: 2.81rem 1.63rem;
  }
  .workSpan{
    display: none;
  }
  .workCards{
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    padding: 0%;
    margin: 0%;
  }
  .workCard{
    width: 100%;
    margin: 0%;
    padding: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 1.25rem;
    border: 2px solid rgba(255, 255, 255, 0.10);
  }
  .workCard1{
    height: 8.8125rem;
  }
  .workCard2{
    height: 8.2rem;
    margin-top: -2.2rem;
  }
  .workCard3{
    height: 7.575rem;
    margin-top: -2.2rem;
  }
  .workCardIcon{
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  }
  .miniCard{
    color: #FFF;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.40625rem; /* 112.5% */
    text-align: left;
    margin-bottom: 1rem;
    width: 60%;
  }
  .projecth2{
    color: #FFF;
    font-size: 6rem;
    font-style: normal;
    font-weight: 500;
  }
  .projecth3{
    color: #FFF;
    font-size: 4rem;
    font-style: normal;
    font-weight: 400;
  }
  .projectCardDiv{
    display: flex;
    padding: 0.89063rem 1.365rem 0.85938rem 1.25rem;
    align-items: flex-start;
  }
  .projectCard{
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.21875rem; /* 139.286% */
    text-transform: uppercase;
    border-radius: 0.875rem;
    border: 2px solid rgba(255, 255, 255, 0.10);
    background: var(--Black, #0D0C0D);
  }
  .clients{
    padding-top: 32rem;
  }
  .clientsBg{
    width: 29.5rem;
    height: 29.625rem;
    flex-shrink: 0;
    border-radius: 29.625rem;
    opacity: 0.2;
    background: var(--Purple, #6343FF);
    filter: blur(150px);
    position: absolute;
    top: 20rem;
  }
  .clientsHeading{
    width: 50%;
    margin: 0 auto;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3rem; /* 126.563% */
    margin-bottom: 2rem;
  }
  .image-div{
    display: none;
  }
  .logos{
    margin: 0%;
  }
  .logosRight{
    margin: 0;
  }
  .visbleMain{
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 0;
  }
  .visiblePara{
    padding: 2.6rem;
    width: 100%;
  }
  .visibleWorkHeading{
    width: 50%;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3rem; /* 126.563% */
    margin: 0%;
    padding: 0%;
    margin-bottom: 2.25rem;
  }
  .visibleWorkPara{
    width: 80%;
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.65625rem; /* 165.625% */
  }
  .visibleImage{
    display: none;
  }
  .visibleWork{
    height: 48.5125rem;
    /* height: auto;
    overflow: hidden; */
    overflow-y: scroll;
    margin: 0 auto;
  }
  .card{
    /* position: relative; */
    height: 48.5125rem;
    margin: 0 auto;
    border-radius: 1.25rem;
    border: 1px solid var(--Grey, #383838);
    background: var(--Black, #0D0C0D);
  }
  .cardContent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cardHeading{
    width: 18.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.125rem; /* 141.667% */
  }
  .cardPara{
    width: 18.75rem;
    color: rgba(255, 255, 255, 0.60);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.96875rem; /* 196.875% */
  }
  .cardPara span{
    display: none;
  }
  .visibleHeadNo{
    color: #FFF;
    font-size: 3rem;
    font-weight: 400;
    line-height: 2.625rem; /* 175% */
  }
  .visibleHeadName{
    color: #FFF;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.625rem; /* 175% */
  }
  .visibleArrow{
    width: 2rem;
    height: 1.7rem;
  }
  .tagDiv{
  }
  .adoredMain{
    margin-top: 4rem;
    overflow-x: hidden;
  }
  .testimonials{
    width: calc(22rem * 12);
    animation: slide2 30s linear infinite;
  }
  @keyframes slide2 {
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-55.7%);
    }
  }
  .testimonials:hover{
    animation-play-state: paused;
  }
  .connect{
    border-radius: 1.125rem;
    height: 17.875rem;
    margin-top: 2rem;
  }
  .connectHead{
    margin: 0 auto;
    color: #FFF;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem; /* 141.667% */
  }
  .connectButton{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 4.4375rem; /* 295.833% */
    width: 16.4375rem;
    height: 3.6875rem;
    border-radius: 0.875rem;
    border: 1px solid #FFF;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connectCube{
    position: absolute;
    top: -9rem;
  }
  .footerMain{
    display: flex;
    gap: 2rem;
  }
  .footerFirst{
    margin-top: 4rem;
    padding: 1rem;
    gap: 2rem;
  }
  .footerGap{
    gap: 30%;
  }
  .footerSecond{
    padding: 1rem;
  }
  .footerThird{
    display: inline-flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .footerPP{
    display: none;
  }
  .footerBase{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
  }
  .navMain{
    width: 90%;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .navVector{
    display: none;
  }
  .burgerButtonDiv{
    display: block;
  }
  .burgerButton{
    display: block;
    fill: #fff;
    width: 2rem;
    cursor: pointer;
  }
  .heroMain{
    text-align: center;
  }
  .navButtons{
    display: none;
  }
  .MobileNav{
    /* position: fixed;
    top: 0;
    left: 0;
    height: 100%; */
    background-color: #0C0C0C;
    display: flex;
    gap: 1.5rem;
    padding-top: 4rem;
    transform: none;
  }
  .navFooter-icons{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: 0 auto;
    width: 60%;
  }
  .navFooter-icon{
    border-radius: 999rem;
    border: 0.7px solid #FFF;
    width: 5rem;
    height: 5rem;
    padding: 1.4rem;
  }
  .navFooter-icon2{
    border-radius: 1.25rem;
    border: 0.7px solid #FFF;
    width: 5rem;
    height: 5rem;
    padding: 1.4rem;
  }
  .slideCards{
    overflow-x: hidden;
  }
  .slideCardSecond{
    display: flex;
  }
  .heroLinks{
    position: absolute;
    bottom: -38rem;
  }
  .heroButton{
    width: 13rem;
    height: 4rem;
    border-radius: 0.875rem;
    font-size: 1.25rem;
  }
  .slideCard{
    width: 21.875rem;
    height: 32.3125rem;
    flex-shrink: 0;
    margin: 0 auto;
    border-radius: 0.625rem;
    animation: cardSlide 70s infinite linear;
  }
  @keyframes cardSlide {
    from{
      transform: translateX(0);
    }
    to{
      transform: translateX(-1200%);
    }
  }
  .slideCardName{
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 5.5rem; /* 366.667% */
    position: absolute;
    bottom: 15%;
  }
  .slideCard:hover{
    pointer-events: none;
    /* transform: scale(1); */
    scale: 1;
  }

  .workHeader{
    display: flex;
    flex-direction: column;
  }

  .workRouteheading{
    margin: 0 auto;
    font-size: 2rem;
    text-align: center;
  }

  .workGrid{
    margin: 0 auto;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .workNav{
    flex-wrap: wrap;
    margin: 2rem auto;
    justify-content: center;
  }

  .contactForm{
    width: 100%;
  }

  .contactHeading{
    font-size: 2rem;
    width: 90%;
    margin: 4rem auto;
  }

  .contactChangeButtons{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
  }

  .contactChangeButton{
    flex-grow: 1;
  }

  .formHeading{
    font-size: 1.625rem;
  }

  .checkMain{
    display: flex;
    flex-wrap: wrap;
  }

  .radioMain{
    display: flex;
    flex-wrap: wrap;
  }

  .changingForm{
    width: 86%;
    margin: 0 auto;
  }

  .inputFieldMain{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .subInputField{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    flex-grow: 1;
  }
  .inputField{
    width: 100%;
  }

  .contactCube{
    display: none;
  }

  .contactFooter{
    margin-top: 5rem;
  }

  .contactFooter-icon{
    all: unset;
    display: flex;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .contact-connect{
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .projectWidth{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 1rem;
  }
  .projectSectionContent{
    margin-top: 12rem;
  }
  .projectNameParent{
    width: 100%;
  }
  .proejectName{
    font-size: 3rem;
  }
  .projectsPara{
    width: 20.875rem;
    color: #FFF;
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.96875rem; /* 196.875% */
  }
  .projectPageSubHeading{
    color: rgba(255, 255, 255, 0.40);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.71875rem; /* 217.5% */
  }
  .projectRightDiv{
    width: 100%;
  }
  .projectPageSideTexts{
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .projectPageSideText{
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    padding: 1rem 0.9rem;
    align-items: flex-start;
    border-radius: 0.875rem;
    border: 2px solid #FFF;
  }
  .headerEllipse{
    position: absolute;
    right: -50%;
    top: -70%;
    z-index: 1;
  }
  .projectImagesDiv{
    width: 90%;
    gap: 1rem;
  }
  .projectGridGap{
    gap: 1rem;
  }
  .projectGridSplit{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .projectArrow{
    position: absolute;
    left: 80%;
  }
  .carousalHeader{
    height: 10rem;
    display: flex;
    gap: 1rem;
    width: 100%;
  }
  .carousalHeading{
    font-size: 1rem;
  }
  .carousalEllipse{
    display: none;
  }
  .workConnect{
    margin-top: 0;
  }
  .carousal{
    animation: carousalAnimation infinite linear 30s;
  }
  
  .carousal:hover{
    animation-play-state: paused;
  }
  
  @keyframes carousalAnimation {
    from{
      transform: translateX(0);
    }
    to{
      transform: translateX(-980%);
    }
  }
  .imageSectionEllipse{
    display: none;
  }
}

@media (max-width:480px){
  .slideNav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transform: translateX(100vw);
    transition: all 1s ease-in;
    z-index: 500;
  }
  .slideNavActive{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: all 1s ease-in;
    transform: translateX(0);
    z-index: 500;
  }
  .crossButton{
    position: fixed;
    right: 10%;
  }
  .mobileView {
    overflow-x: hidden;
  }
  .service-main{
    width: 100%;
  }
  .hero-cube{
    position: absolute;
    top: 12rem;
    left: -8rem;
    width: 35rem;
    flex-shrink: 0;
  }
  .mainHeading{
    color: #FFF;
    text-align: center;
    font-family: Clash Display;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.71875rem;
  }
  .mainHeadingSpan{
    color: #FFF;
    font-size: 2.5rem;
    font-style: normal;
    line-height: 2.71875rem;
  }
  .headPara{
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.65625rem; /* 165.625% */
    width: 19.375rem;
  }
  .sideText{
    color: #FFF;
    text-align: right;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3rem; /* 352.5% */
    text-transform: uppercase;
    position: absolute;
    top: 12rem;
    right: 0;
  }
  .mainDotted{
    position: absolute;
    width: 50.8125rem;
    height: 27.5rem;
    top: 20rem;
  }
  .mainEllipse1{
    position: absolute;
    top: 30rem;
  }
  .mainEllipse2{
    display: none;
  }
  .mainArrow{
    position: absolute;
    top: 43rem;
    right: 8rem;
    width: 2.15619rem;
    height: 2.15619rem;
    transform: rotate(45deg);
  }
  .mainArrow p{
    display: none;
  }
  .serviceHeading{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
    color: #FFF;
    font-family: Clash Display;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.53125rem; /* 126.563% */
    text-align: left;
    padding: 0rem 2rem;
  }
  .service-mainHeading{
    color: #FFF;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.53125rem; /* 126.563% */
    width: 19.125rem;
  }
  .serviceSubHeading{
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.65625rem; /* 165.625% */
    width: 13.875rem;
  }
  .serviceCards{
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0;
  }
  .serviceCard{
    width: 100%;
    height: auto;
    border: none;
    border-radius: 0%;
    border-bottom: 1px solid var(--Grey, #383838);
    margin: 0%;
  }
  .snippetHead{
    display: none;
  }
  .snippetHeadMob{
    display: block;
    color: #FFF;
    text-align: center;
    font-size: 2rem;
    line-height: 2.53125rem; /* 126.563% */
    margin-top: 0;
  }
  .slidesButton{
    margin: 0 auto;
    margin-top: 3rem;
    width: 10.0625rem;
    height: 3.8125rem;
    flex-shrink: 0;
    border-radius: 0.875rem;
    border: 1px solid #FFF;
    /* background: url(<path-to-image>), lightgray -92382672126396480px -1024112734620590100px / 1664053872424383700% 1691450316963701000% no-repeat; */
  }
  .workMain{
    height: 47rem;
    margin: 0 auto;
    padding: 0%;
    padding-top: 5.2rem;
    z-index: 100;
  }
  .workAboutSection{
    width: 22.375rem;
    height: 41.8125rem;
    margin: 0 auto;
    padding: 0 ;
    padding-top: 3rem;
    border-radius: 1.25rem;
    border: 1px solid var(--Grey, #383838);
    background: var(--Black, #0D0C0D);
  }
  .workButton{
    display: none;
  }
  .workBG{
    display: none;
  }
  .workDiv{
    margin: 0%;
    padding: 0%;
  }
  .workHeading{
    width: 19.125rem;
    font-size: 2rem;
    line-height: 2.53125rem; /* 126.563% */
    margin: 0 1.63rem;
  }
  .workPara{
    width: 19.375rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.65625rem; /* 165.625% */
    margin: 2.81rem 1.63rem;
  }
  .workSpan{
    display: none;
  }
  .workCards{
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 22.375rem;
    padding: 0%;
    margin: 0%;
  }
  .workCard{
    margin: 0%;
    padding: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 1.25rem;
    border: 2px solid rgba(255, 255, 255, 0.10);
  }
  .workCard1{
    width: 22.375rem;
    height: 8.8125rem;
  }
  .workCard2{
    width: 22.375rem;
    height: 8.2rem;
    margin-top: -2.2rem;
  }
  .workCard3{
    width: 22.45rem;
    height: 7.575rem;
    margin-top: -2.2rem;
  }
  .workCardIcon{
    width: 2.5145rem;
    height: 2.52888rem;
    margin-bottom: 1rem;
  }
  .miniCard{
    color: #FFF;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.40625rem; /* 112.5% */
    text-align: left;
    margin-bottom: 1rem;
  }
  .projecth2{
    color: #FFF;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 5.5rem; /* 157.143% */
  }
  .projecth3{
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 5.5rem; /* 366.667% */
  }
  .projectCardDiv{
    display: flex;
    padding: 0.89063rem 1.365rem 0.85938rem 1.25rem;
    align-items: flex-start;
  }
  .projectCard{
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.21875rem; /* 139.286% */
    text-transform: uppercase;
    border-radius: 0.875rem;
    border: 2px solid rgba(255, 255, 255, 0.10);
    background: var(--Black, #0D0C0D);
  }
  .clients{
    padding-top: 32rem;
  }
  .clientsBg{
    width: 29.5rem;
    height: 29.625rem;
    flex-shrink: 0;
    border-radius: 29.625rem;
    opacity: 0.2;
    background: var(--Purple, #6343FF);
    filter: blur(150px);
    position: absolute;
    top: 20rem;
  }
  .clientsHeading{
    width: 14.625rem;
    margin: 0 auto;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.53125rem; /* 126.563% */
    margin-bottom: 2rem;
  }
  .image-div{
    display: none;
  }
  .logos{
    margin: 0%;
  }
  .logosRight{
    margin: 0;
  }
  .visbleMain{
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 0;
  }
  .visiblePara{
    padding: 2.6rem;
  }
  .visibleWorkHeading{
    width: 10.125rem;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.53125rem; /* 126.563% */
    margin: 0%;
    padding: 0%;
    margin-bottom: 2.25rem;
  }
  .visibleWorkPara{
    width: 19.375rem;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    overflow-y: scroll;
    line-height: 1.65625rem; /* 165.625% */
  }
  .visibleImage{
    display: none;
  }
  .visibleWork{
    height: 48.5125rem;
    /* height: auto;
    overflow: hidden; */
    overflow-y: scroll;
    margin: 0 auto;
  }
  .card{
    /* position: relative; */
    width: 22.5rem;
    height: 48.5125rem;
    flex-shrink: 0;
    margin: 0 auto;
    border-radius: 1.25rem;
    border: 1px solid var(--Grey, #383838);
    background: var(--Black, #0D0C0D);
  }
  .cardContent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cardHeading{
    width: 18.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.125rem; /* 141.667% */
  }
  .cardPara{
    width: 18.75rem;
    color: rgba(255, 255, 255, 0.60);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.96875rem; /* 196.875% */
  }
  .cardPara span{
    display: none;
  }
  .visibleHeadNo{
    color: #FFF;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.625rem; /* 175% */
  }
  .visibleHeadName{
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.625rem; /* 175% */
  }
  .visibleLink{
    display: none;
  }
  .visibleArrow{
    width: 2rem;
    height: 1.7rem;
  }
  .tagDiv{
    display: none;
  }
  .adoredMain{
    margin-top: 4rem;
    overflow-x: hidden;
  }
  .testimonials{
    width: calc(22rem * 12);
    animation: slide2 30s linear infinite;
  }
  @keyframes slide2 {
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-55.7%);
    }
  }
  .testimonials:hover{
    animation-play-state: paused;
  }
  .connect{
    border-radius: 1.125rem;
    width: 24.375rem;
    height: 17.875rem;
    margin-top: 2rem;
  }
  .connectHead{
    width: 17.6875rem;
    margin: 0 auto;
    color: #FFF;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem; /* 141.667% */
  }
  .connectButton{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 4.4375rem; /* 295.833% */
    width: 16.4375rem;
    height: 3.6875rem;
    border-radius: 0.875rem;
    border: 1px solid #FFF;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connectCube{
    position: absolute;
    top: -4rem;
    right: -5rem;
  }
  .footerMain{
    display: flex;
    gap: 2rem;
  }
  .footerFirst{
    margin-top: 4rem;
    padding: 1rem;
    gap: 2rem;
  }
  .footerSecond{
    padding: 1rem;
  }
  .footerThird{
    display: inline-flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .footerPP{
    display: none;
  }
  .footerBase{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
  }
  .navMain{
    width: 90%;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .navVector{
    display: none;
  }
  .burgerButtonDiv{
    display: block;
  }
  .burgerButton{
    display: block;
    fill: #fff;
    width: 2rem;
    cursor: pointer;
  }
  .heroMain{
    text-align: center;
  }
  .navButtons{
    display: none;
  }
  .MobileNav{
    /* position: fixed;
    top: 0;
    left: 0;
    height: 100%; */
    background-color: #0C0C0C;
    display: flex;
    gap: 1.5rem;
    padding-top: 4rem;
    transform: none;
  }
  .navFooter-icons{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: 0 auto;
    width: 60%;
  }
  .navFooter-icon{
    border-radius: 999rem;
    border: 0.7px solid #FFF;
    width: 5rem;
    height: 5rem;
    padding: 1.4rem;
  }
  .navFooter-icon2{
    border-radius: 1.25rem;
    border: 0.7px solid #FFF;
    width: 5rem;
    height: 5rem;
    padding: 1.4rem;
  }
  .slideCards{
    overflow-x: hidden;
  }
  .slideCardSecond{
    display: flex;
  }
  .heroLinks{
    display: none;
  }
  .slideCard{
    width: 21.875rem;
    height: 32.3125rem;
    flex-shrink: 0;
    margin: 0 auto;
    border-radius: 0.625rem;
    animation: cardSlide 70s infinite linear;
  }
  @keyframes cardSlide {
    from{
      transform: translateX(0);
    }
    to{
      transform: translateX(-1200%);
    }
  }
  .slideCardName{
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 5.5rem; /* 366.667% */
    position: absolute;
    bottom: 15%;
  }
  .slideCard:hover{
    pointer-events: none;
    /* transform: scale(1); */
    scale: 1;
  }

  .workHeader{
    display: flex;
    flex-direction: column;
  }

  .workRouteheading{
    margin: 0 auto;
    font-size: 2rem;
    text-align: center;
  }

  .workGrid{
    margin: 0 auto;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(1, 1fr);
  }

  .workNav{
    flex-wrap: wrap;
    margin: 2rem auto;
    justify-content: center;
  }

  .contactForm{
    width: 100%;
  }

  .contactHeading{
    font-size: 2rem;
    width: 90%;
    margin: 4rem auto;
  }

  .contactChangeButtons{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
  }

  .contactChangeButton{
    flex-grow: 1;
  }

  .formHeading{
    font-size: 1.625rem;
  }

  .checkMain{
    display: flex;
    flex-wrap: wrap;
  }

  .radioMain{
    display: flex;
    flex-wrap: wrap;
  }

  .changingForm{
    width: 86%;
    margin: 0 auto;
  }

  .inputFieldMain{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .subInputField{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    flex-grow: 1;
  }
  .inputField{
    width: 100%;
  }

  .contactCube{
    display: none;
  }

  .contactFooter{
    margin-top: 5rem;
  }

  .contactFooter-icon{
    all: unset;
    display: flex;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .contact-connect{
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .projectWidth{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 1rem;
  }
  .projectSectionContent{
    margin-top: 3rem;
  }
  .projectNameParent{
    width: 100%;
  }
  .proejectName{
    font-size: 3rem;
  }
  .projectsPara{
    width: 20.875rem;
    color: #FFF;
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.96875rem; /* 196.875% */
  }
  .projectPageSubHeading{
    color: rgba(255, 255, 255, 0.40);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.71875rem; /* 217.5% */
  }
  .projectRightDiv{
    width: 100%;
  }
  .projectPageSideTexts{
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .projectPageSideText{
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    padding: 1rem 0.9rem;
    align-items: flex-start;
    border-radius: 0.875rem;
    border: 2px solid #FFF;
  }
  .headerEllipse{
    position: absolute;
    right: -50%;
    top: -70%;
    z-index: 1;
  }
  .projectImagesDiv{
    width: 90%;
    gap: 1rem;
  }
  .projectGridGap{
    gap: 1rem;
  }
  .projectGridSplit{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .projectArrow{
    position: absolute;
    left: 80%;
  }
  .carousalHeader{
    height: 10rem;
    display: flex;
    gap: 1rem;
    width: 100%;
  }
  .carousalHeading{
    font-size: 1rem;
  }
  .carousalEllipse{
    display: none;
  }
  .workConnect{
    margin-top: 0;
  }
  .carousal{
    animation: carousalAnimation infinite linear 30s;
  }
  
  .carousal:hover{
    animation-play-state: paused;
  }
  
  @keyframes carousalAnimation {
    from{
      transform: translateX(0);
    }
    to{
      transform: translateX(-980%);
    }
  }
  .imageSectionEllipse{
    display: none;
  }
}